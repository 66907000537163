.owl-carousel .owl-dots .owl-dot span {
    background: #ff4c3b; // Change this to your desired color
    width: 12px; // Optional: Change the size of the dots
    height: 12px; // Optional: Change the size of the dots
    border-radius: 50%; // Optional: Makes the dots circular
    transition: background 0.3s; // Optional: Adds a transition effect
  }
  
  .owl-carousel .owl-dots .owl-dot.active span {
    background: #000000; // Change this to your desired color for active dot
  }
/* You can add global styles to this file, and also import other style files */
