.owl-carousel .owl-dots .owl-dot span {
  background: #ff4c3b;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: background 0.3s;
}

.owl-carousel .owl-dots .owl-dot.active span {
  background: #000000;
}

/* You can add global styles to this file, and also import other style files */